<template>
  <div class="contact-complete">
    <!-- notify -->
    <p>お問い合わせいただきありがとうございました。<br>
      お問い合わせを受け付けました。</p>

    <p>折り返し、担当者よりご連絡いたしますので、<br>
      恐れ入りますが、しばらくお待ちください。</p>

    <p>なお、ご入力いただいたメールアドレス宛に受付完了メールを配信しております。<br>
      完了メールが届かない場合、処理が正常に行われていない可能性があります。<br>
      大変お手数ですが、再度お問い合わせの手続きをお願い致します。</p>

    <!-- btn back to home -->
    <a @click.prevent="openPageNative('Index')"
                  class="btn-back-home">
      ホームへ
      <ChevronRightIcon size="24"/>
    </a>
  </div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'

export default {
  name: 'Complete',

  mixins: [Common],

  components: {
    ChevronRightIcon
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-complete {
  padding: 53px 84px 70px;
  text-align: center;
  @media #{$info-tablet-vertical} {
    padding: 53px 40px 70px;
  }
  @media #{$info-phone} {
    padding: 48px 18px;
    text-align: left;
  }
  p {
    @include font-size(18px);
    margin-bottom: 40px;
    @media #{$info-phone} {
      font-size: 18px ;
      margin-bottom: 35px;
    }
  }
  .btn-back-home {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    background: #6F8784;
    letter-spacing: 2px;
    font-family: $font-family-title;
    @include font-size(18px);
    padding: 14px;
    margin: auto;
    cursor: pointer;
    @media #{$info-phone} {
      font-size: 16px;
      padding: 10px;
      max-width: 100%;
      margin-top: 140px;
    }
    svg {
      margin-left: 28px;
    }
  }
}
</style>
